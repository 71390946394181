import { Container } from 'react-bootstrap';
import { Book, PersonVideo, Images } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

export default function NewPublication() {
  const navigate = useNavigate();

  return (
    <Container className="d-flex justify-content-center align-items-center mt-5">
      <button
        className="p-4 me-3 btn btn-outline-dark d-flex flex-column align-items-center"
        onClick={() => navigate('/dashboard/content/article')}
      >
        <Book size={32} />
        <p className="mt-2 mb-0">Artículo</p>
      </button>
      <button
        className="p-4 me-3 btn btn-outline-dark d-flex flex-column align-items-center"
        onClick={() => navigate('/dashboard/content/gallery')}
      >
        <Images size={32} />
        <p className="mt-2 mb-0">Galería</p>
      </button>
      <button
        className="p-4 btn btn-outline-dark d-flex flex-column align-items-center"
        onClick={() => navigate('/dashboard/content/video')}
      >
        <PersonVideo size={32} />
        <p className="mt-2 mb-0">Video</p>
      </button>
    </Container>
  );
}
