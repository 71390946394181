import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './view/pages/Login';
import Dashboard from './view/pages/Dashboard';
import Content from './view/pages/Content';
import Article from './view/pages/Article';
import Backlog from './view/pages/Backlog';
import NewPublication from './view/pages/NewPublication';
import Pasarela from './view/pages/Pasarela';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    children: [
      {
        path: 'backlog',
        element: <Backlog />,
      },
      {
        path: 'content',
        element: <Content />,
      },
      {
        path: 'content/new',
        element: <NewPublication />,
      },
      {
        path: 'content/article',
        element: <Article />,
      },
      {
        path: 'content/gallery',
        element: <Article type={'gallery'} />,
      },
      {
        path: 'content/video',
        element: <Article type={'video'} />,
      },
      {
        path: 'content/article/:id',
        element: <Article />,
      },
      {
        path: 'content/gallery/:id',
        element: <Article type={'gallery'} />,
      },
      {
        path: 'content/video/:id',
        element: <Article type={'video'} />,
      },
      {
        path: 'pasarela',
        element: <Pasarela />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
