import Kalend, { CalendarView } from 'kalend';
import 'kalend/dist/styles/index.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Content() {
  const navigate = useNavigate();

  const [content, setContent] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/content`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        setContent(
          data.map((item) => {
            const endAt = new Date(item.publishOn);
            endAt.setMinutes(endAt.getMinutes() + 30);

            let color = 'blue';
            if (item.status === 'draft') {
              color = 'gray';
            } else if (
              item.status === 'published' &&
              new Date(item.publishOn) < new Date()
            ) {
              color = 'green';
            }

            let types = {
              article: 'A',
              gallery: 'G',
              video: 'V',
            };

            let sections = {
              moda: 'M',
              belleza: 'B',
              lifestyle: 'L',
            };

            return {
              id: item.id,
              startAt: item.publishOn,
              endAt: endAt.toISOString(),
              summary: `${types[item.type]}${sections[item.section]} ${
                item.title
              }`,
              type: item.type,
              color,
            };
          })
        );
      });
  }, []);

  return (
    <div style={{ height: '90vh', maxWidth: '100%' }}>
      <Kalend
        onEventClick={(e) => navigate(`/dashboard/content/${e.type}/${e.id}`)}
        onNewEventClick={() => console.log('new event click')}
        events={content}
        initialDate={new Date().toISOString()}
        hourHeight={60}
        initialView={CalendarView.MONTH}
        onSelectView={(selectedView) => {}}
        onPageChange={(date) => {}}
        timeFormat={'24'}
        weekDayStart={'Monday'}
        language={'es'}
      />
    </div>
  );
}
