import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import LinkTool from '@editorjs/link';
import Image from '@editorjs/image';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import Personality from '@editorjs/personality';
import RawTool from '@editorjs/raw';

export const EDITOR_JS_TOOLS = {
  embed: {
    class: Embed,
    config: {
      services: {
        youtube: true,
        instagram: true,
        twitter: true,
        pinterest: true,
        tiktok: {
          regex:
            /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@(?<username>[a-zA-Z0-9_\.]+)\/video\/(?<id>[0-9]+)/,
          embedUrl: 'https://www.tiktok.com/embed/v2/<%= remote_id %>',
          html: '<blockquote class="tiktok-embed" cite="<%= url %>" data-video-id="<%= remote_id %>"><section><a href="<%= url %>"></a></section></blockquote> <script async src="https://www.tiktok.com/embed.js"></script>',
          width: 480,
          height: 480,
        },
      },
    },
  },
  table: Table,
  list: {
    class: List,
    inlineToolbar: true,
  },
  warning: Warning,
  linkTool: LinkTool,
  image: {
    class: Image,
    config: {
      endpoints: {
        byFile: `${process.env.REACT_APP_API}/api/media/image`,
        byUrl: `${process.env.REACT_APP_API}/api/media/image-url`,
      },
    },
  },
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  personality: Personality,
  raw: RawTool,
};
