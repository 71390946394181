import { useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';

export function Seasons({ api }) {
  const [seasons, setSeasons] = useState([]);
  const [newSeason, setNewSeason] = useState({
    name: '',
    slug: '',
  });

  const addSeason = () => {
    fetch(`${process.env.REACT_APP_API}/api/pasarela/${api}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },

      body: JSON.stringify({ ...newSeason }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        return response;
      })

      .then((response) => response.json())
      .then((data) => {
        setSeasons(data);
        setNewSeason({ name: '', slug: '' });
      });
  };

  const refreshSeasons = () => {
    fetch(`${process.env.REACT_APP_API}/api/pasarela/${api}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        return response;
      })

      .then((response) => response.json())
      .then((data) => {
        setSeasons(data);
      });
  };

  useEffect(() => {
    refreshSeasons();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <input
          type="text"
          className="form-control me-2"
          value={newSeason.name}
          placeholder="Nombre"
          onChange={(e) => setNewSeason({ ...newSeason, name: e.target.value })}
        />

        <input
          type="text"
          className="form-control me-2"
          value={newSeason.slug}
          placeholder="Slug"
          onChange={(e) => setNewSeason({ ...newSeason, slug: e.target.value })}
        />

        <Button className="btn" onClick={addSeason}>
          +
        </Button>
      </div>
      <ListGroup className="mt-2 w-100 d-flex flex-column">
        {seasons.map((season) => (
          <ListGroup.Item
            key={season.id}
            className="d-flex justify-content-between align-items-center"
          >
            {season.name} <span>{season.slug}</span>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}
