import { toast } from "react-toastify";

export function headers() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
}

export function checkAndJson(res) {
  if (!res.ok) {
    toast.error(res);
  }
  return res.json();
}

export async function post(url, data) {
  let res;
  try {
    res = await fetch(`${process.env.REACT_APP_API}${url}`, {
      method: "POST",
      headers: headers(),
      body: JSON.stringify(data),
    });
  } catch (error) {
    toast.error("Error de conexión");
  }

  return checkAndJson(res);
}

export async function get(url) {
  let res;
  try {
    res = await fetch(`${process.env.REACT_APP_API}${url}`, {
      headers: headers(),
    });
  } catch (error) {
    toast.error("Error de conexión");
  }
  return checkAndJson(res);
}

export async function put(url, data) {
  let res;
  try {
    res = await fetch(`${process.env.REACT_APP_API}${url}`, {
      method: "PUT",
      headers: headers(),
      body: JSON.stringify(data),
    });
  } catch (error) {
    toast.error("Error de conexión");
  }
  return checkAndJson(res);
}
