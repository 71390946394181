import { useEffect, useState } from 'react';
import { Container, ListGroup, ListGroupItem } from 'react-bootstrap';

export default function Backlog() {
  const [backlog, setBacklog] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/backlog`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        //data by type
        setBacklog(
          data.reduce((acc, item) => {
            if (!acc[item.type]) {
              acc[item.type] = [];
            }
            acc[item.type].push(item);
            return acc;
          }, {})
        );
      });
  }, []);

  return (
    <Container className="mt-3">
      <h1>Backlog</h1>
      <ListGroup>
        <ListGroupItem variant="dark">Articles</ListGroupItem>
        {backlog.article?.map((item) => (
          <ListGroupItem
            action={true}
            key={item.id}
            href={`/dashboard/content/article/${item.id}`}
          >
            {item.title}
          </ListGroupItem>
        ))}
        <ListGroupItem variant="dark">Videos</ListGroupItem>
        {backlog.video?.map((item) => (
          <ListGroupItem
            action={true}
            key={item.id}
            href={`/dashboard/content/article/${item.id}`}
          >
            {item.title}
          </ListGroupItem>
        ))}
        <ListGroupItem variant="dark">Galerías</ListGroupItem>
        {backlog.gallery?.map((item) => (
          <ListGroupItem
            action={true}
            key={item.id}
            href={`/dashboard/content/article/${item.id}`}
          >
            {item.title}
          </ListGroupItem>
        ))}
      </ListGroup>
    </Container>
  );
}
