import { useEffect, useState } from "react";
import { Accordion, Button, Tabs, Tab } from "react-bootstrap";
import { Seasons } from "../components/pasarela/Seasons";
import { get, post, put } from "../components/utils";
import { toast } from "react-toastify";

export default function Pasarela() {
  const [activeTab, setActiveTab] = useState("brands");
  const [data, setData] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/pasarela/seasons`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((seasons) => {
        fetch(`${process.env.REACT_APP_API}/api/pasarela/fashion-events`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
          .then((response2) => response2.json())
          .then((fashionEvents) => {
            setData({ seasons, fashionEvents });
          });
      });
  }, []);

  return (
    <div className="w-100 m-0">
      <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
        <Tab eventKey="brands" title="Marcas">
          <Brands data={data} />
        </Tab>
        <Tab eventKey="seasons" title="Temporadas">
          <Seasons api="seasons" />
        </Tab>
        <Tab eventKey="eventos" title="Eventos">
          <Seasons api="fashion-events" />
        </Tab>
      </Tabs>
    </div>
  );
}

function Brands({ data }) {
  const [openNewBrand, setOpenNewBrand] = useState(false);
  const [brands, setBrands] = useState([]);
  const [collections, setCollections] = useState([]);

  const [newBrand, setNewBrand] = useState({
    name: "",
    slug: "",
  });
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const addBrand = () => {
    post("/api/pasarela/brands", { ...newBrand }).then((data) => {
      setBrands(data);
      setNewBrand({ name: "", slug: "" });
    });
  };

  const refreshBrands = () => get("/api/pasarela/brands").then((data) => setBrands(data));
  const refreshCollections = () => get(`/api/pasarela/brands/${selectedBrand.slug}/collections`).then((data) => setCollections(data));

  useEffect(() => {
    refreshBrands();
  }, []);

  return (
    <>
      <Button
        className="btn"
        onClick={() => setOpenNewBrand(!openNewBrand)}
        style={{ position: "absolute", top: "62px", right: "0", borderRadius: "0" }}
      >
        {openNewBrand ? "-" : "+"}
      </Button>
      {openNewBrand && (
        <div className="d-flex justify-content-between align-items-center mt-2">
          <input
            type="text"
            className="form-control me-2"
            value={newBrand.name}
            placeholder="Nueva marca"
            onChange={(e) => setNewBrand({ ...newBrand, name: e.target.value })}
          />

          <input
            type="text"
            className="form-control me-2"
            value={newBrand.slug}
            placeholder="Slug"
            onChange={(e) => setNewBrand({ ...newBrand, slug: e.target.value })}
          />

          <Button className="btn" onClick={addBrand}>
            +
          </Button>
        </div>
      )}
      <div className="d-flex mt-2">
        <div className="mt-2 d-flex flex-column" style={{ width: selectedCollection ? "10%" : "15%" }}>
          {brands.map((brand) => (
            <Brand
              key={brand.id}
              brand={brand}
              data={data}
              refreshBrands={refreshBrands}
              setSelectedBrand={setSelectedBrand}
              selectedBrand={selectedBrand}
              refreshCollections={refreshCollections}
              selectedCollection={selectedCollection}
            />
          ))}
        </div>
        {selectedBrand && (
          <div className="ps-1 mt-2 d-flex flex-column" style={{ width: selectedCollection ? "90%" : "85%" }}>
            <Collections
              brandSlug={selectedBrand.slug}
              data={data}
              refreshCollections={refreshCollections}
              collections={collections}
              setSelectedCollection={setSelectedCollection}
              selectedCollection={selectedCollection}
            />
          </div>
        )}
      </div>
    </>
  );
}

function Brand({ brand, refreshBrands, selectedBrand, setSelectedBrand, refreshCollections, selectedCollection }) {
  const [brandForm, setBrandForm] = useState({
    name: brand.name,
    slug: brand.slug,
    url: brand.url || "",
  });

  const [newCollection, setNewCollection] = useState({
    name: "",
    slug: "",
  });

  const updateBrand = () => {
    put(`/api/pasarela/brands/${brand.slug}`, { ...brandForm }).then(() => {
      toast.success("Marca actualizada");
      refreshBrands();
    });
  };

  const toggleBrandStatus = () => {
    post(`/api/pasarela/brands/${brand.slug}/toggle-status`).then(() => {
      refreshBrands();
    });
  };

  const addCollection = () => {
    post(`/api/pasarela/collections`, {
      name: newCollection.name,
      slug: newCollection.slug,
      brandSlug: brand.slug,
    }).then(() => {
      toast.success("Colección creada");
      setNewCollection({ name: "", slug: "" });
      refreshCollections();
    });
  };

  return (
    <>
      <div
        onClick={() => setSelectedBrand(brand)}
        className="d-flex justify-content-between align-items-center"
        style={{ cursor: "pointer", background: brand.slug === selectedBrand?.slug ? "lightgrey" : "white" }}
      >
        {brand.name}
        <Button className="btn btn-link" onClick={toggleBrandStatus}>
          {brand.status === "published" ? "o" : "x"}
        </Button>
      </div>
      {!selectedCollection && brand.slug === selectedBrand?.slug && (
        <div style={{ background: "#f3f3f3" }}>
          <div className="d-flex flex-column justify-content-between align-items-center mb-2 border p-2 mt-2">
            <input
              type="text"
              className="form-control mb-2"
              value={brandForm.name}
              placeholder="Nombre"
              onChange={(e) => setBrandForm({ ...brandForm, name: e.target.value })}
            />
            <input
              type="text"
              className="form-control mb-2"
              value={brandForm.slug}
              placeholder="Slug"
              onChange={(e) => setBrandForm({ ...brandForm, slug: e.target.value })}
            />

            <input
              type="text"
              className="form-control mb-2"
              value={brandForm.url}
              placeholder="URL"
              onChange={(e) => setBrandForm({ ...brandForm, url: e.target.value })}
            />

            <Button className="btn" onClick={updateBrand}>
              Guardar
            </Button>
          </div>

          <div className="d-flex flex-column mb-4 border p-2 mt-2">
            <input
              type="text"
              className="form-control mb-2"
              value={newCollection.name}
              placeholder="Nueva colección"
              onChange={(e) => setNewCollection({ ...newCollection, name: e.target.value })}
            />
            <input
              type="text"
              className="form-control mb-2"
              value={newCollection.slug}
              placeholder="Slug"
              onChange={(e) => setNewCollection({ ...newCollection, slug: e.target.value })}
            />
            <Button className="btn" onClick={addCollection}>
              +
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

function Collections({ brandSlug, data, collections, refreshCollections, selectedCollection, setSelectedCollection }) {
  useEffect(() => {
    refreshCollections();
  }, [brandSlug]);

  useEffect(() => {
    if (!selectedCollection) return;

    setSelectedCollection(collections.find((collection) => collection.slug === selectedCollection?.slug));
  }, [collections]);

  return (
    <div className="d-flex w-100">
      <div className="mt-2 d-flex flex-column" style={{ width: "15%" }}>
        {collections.map((collection) => (
          <Collection
            key={collection.id}
            collection={collection}
            refreshCollection={refreshCollections}
            data={data}
            setSelectedCollection={setSelectedCollection}
            selectedCollection={selectedCollection}
          />
        ))}
      </div>
      <div
        className="mt-2 d-flex 
      justify-content-between align-items-center flex-column
      "
        style={{ width: "85%" }}
      >
        {selectedCollection && <Looks collection={selectedCollection} refreshCollection={refreshCollections} />}
      </div>
    </div>
  );
}

function Collection({ collection, refreshCollection, data, setSelectedCollection, selectedCollection }) {
  const [collectionForm, setCollectionForm] = useState({
    name: collection.name,
    slug: collection.slug,
    season: collection.season?.id,
    fashionEvent: collection.fashionEvent?.id,
  });

  const updateCollection = () => {
    put(`/api/pasarela/collections/${collection.slug}`, { ...collectionForm }).then(() => {
      toast.success("Colección actualizada");
      refreshCollection();
    });
  };

  const toggleCollectionHighlight = () => {
    post(`/api/pasarela/collections/${collection.slug}/toggle-highlight`).then(() => {
      toast.success("Colección actualizada");
      refreshCollection();
    });
  };

  const toggleCollectionStatus = () => {
    post(`/api/pasarela/collections/${collection.slug}/toggle-status`).then(() => {
      toast.success("Colección actualizada");
      refreshCollection();
    });
  };

  return (
    <div style={{ background: collection.slug === selectedCollection?.slug ? "lightgrey" : "white", cursor: "pointer" }}>
      <div className="cursor-pointer" onClick={() => setSelectedCollection(collection)}>
        {collection.season && collection.season.name} - {collection.name}
        <Button className="btn btn-link ms-2 p-0" onClick={toggleCollectionStatus}>
          {collection.status === "published" ? "o" : "x"}
        </Button>{" "}
        <Button
          className="btn btn-link ms-2 p-0"
          onClick={toggleCollectionHighlight}
          style={collection.isHighlight ? { color: "red" } : { color: "#ddd" }}
        >
          {collection.isHighlight ? "^" : "v"}
        </Button>
      </div>

      {collection.slug === selectedCollection?.slug && (
        <div
          className="d-flex flex-column p-2 justify-content-between align-items-center mb"
          style={{ background: collection.slug === selectedCollection?.slug ? "#fdfdfd" : "white" }}
        >
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Nombre"
            value={collectionForm.name}
            onChange={(e) => setCollectionForm({ ...collectionForm, name: e.target.value })}
          />
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Slug"
            value={collectionForm.slug}
            onChange={(e) => setCollectionForm({ ...collectionForm, slug: e.target.value })}
          />
          <select
            className="form-control mb-2"
            value={collectionForm.season}
            onChange={(e) => setCollectionForm({ ...collectionForm, season: e.target.value })}
          >
            <option value={""}>Temporada</option>
            {data.seasons?.map((season) => (
              <option key={season.id} value={season.id}>
                {season.name}
              </option>
            ))}
          </select>

          <select
            className="form-control mb-2"
            value={collectionForm.fashionEvent}
            onChange={(e) =>
              setCollectionForm({
                ...collectionForm,
                fashionEvent: e.target.value,
              })
            }
          >
            <option value={""}>Evento</option>
            {data.fashionEvents?.map((fashionEvent) => (
              <option key={fashionEvent.id} value={fashionEvent.id}>
                {fashionEvent.name}
              </option>
            ))}
          </select>
          <Button className="btn" onClick={updateCollection}>
            Guardar
          </Button>
        </div>
      )}
    </div>
  );
}

function Looks({ collection, refreshCollection }) {
  const [images, setImages] = useState([]);

  const uploadLooks = (images) => {
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("images[]", images[i]);
    }

    fetch(`${process.env.REACT_APP_API}/api/pasarela/collections/${collection.slug}/looks`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          toast.error("Error al subir las imágenes");
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Imágenes subidas");
        refreshCollection();
      });
  };

  return (
    <>
      <div>
        <input
          type="file"
          multiple
          onChange={(e) => {
            setImages(e.target.files);
          }}
        />
        <Button
          onClick={() => {
            uploadLooks(images);
          }}
        >
          Subir
        </Button>
      </div>
      <div className="d-flex flex-wrap mt-2">
        {collection.looks.map((image) => (
          <div key={image.id} className="position-relative me-2 mb-2">
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                background: "white",
                color: "black",
                padding: "4px",
              }}
            >
              {image.number}
            </div>
            <img
              key={image.id}
              src={`${process.env.REACT_APP_PASARELA_MEDIA}${image.imageUrl}`}
              alt={image.name}
              className="img-fluid me-2 mb-2"
              style={{ width: "200px", height: "300px", objectFit: "cover" }}
            />
            <textarea
              className="form-control"
              defaultValue={image.caption || ""}
              onBlur={(e) => {
                // debounce log
                put(`/api/pasarela/looks/${image.id}`, {
                  caption: e.target.value,
                }).then(() => {
                  toast.success("Caption actualizado");
                });
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
}
