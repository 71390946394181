import { Col, Container, Row } from 'react-bootstrap';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_JS_TOOLS } from '../components/tools';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

const ReactEditorJS = createReactEditorJS();

export default function Article({ type = 'article' }) {
  const { register, handleSubmit, setValue, reset } = useForm();
  const navigate = useNavigate();

  const [body, setBody] = useState({});
  const [initialData, setInitialData] = useState(null);

  const [frontImage, setFrontImage] = useState({});
  const [initialFrontImage, setInitialFrontImage] = useState(null);

  const [pubType, setPubType] = useState(type);

  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      setInitialData({});
      setBody({});

      setInitialFrontImage({});
      setFrontImage({});

      reset();
      setValue('type', type);
      return;
    }

    fetch(`${process.env.REACT_APP_API}/api/publications/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        const { body, frontImage, publishOn, writtenBy, tags, ...form } = data;

        setInitialData(body);
        setBody(body);
        setInitialFrontImage(frontImage);
        setFrontImage(frontImage);

        if (publishOn) {
          setValue('publishOn', publishOn.substring(0, 16));
        }

        if (writtenBy) {
          setValue('writtenBy', writtenBy.id);
        }

        setValue('tags', tags.map((tag) => tag.name).join(', '));
        Object.keys(form).forEach((key) => setValue(key, form[key]));
        setPubType(form.type);
      });
  }, [id]);

  const onSubmit = (data) => {
    fetch(`${process.env.REACT_APP_API}/api/publications/create-article`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then((response) => response.json())
      .then((newId) => {
        navigate(`/dashboard/content/article/${newId}`);
        toast.success('Publicación guardada');
      });
  };

  const deletePublication = () => {
    fetch(`${process.env.REACT_APP_API}/api/publications/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(() => {
      navigate('/dashboard/backlog');
      toast.success('Publicación eliminada');
    });
  };

  return (
    <Container className="my-3 mb-5 pb-5">
      <h2 className="mx-2 px-4 mb-4">
        {
          {
            article: 'Artículo',
            gallery: 'Galería',
            video: 'Video',
          }[type]
        }
      </h2>

      <form
        className="pb-4"
        onSubmit={handleSubmit((data) =>
          onSubmit({ ...data, body, frontImage })
        )}
      >
        <input type="hidden" {...register('id')} />
        <Row>
          <Col lg={8} className="px-5">
            <input type="hidden" disabled {...register('type')} />
            <label className="form-label" htmlFor="title">
              Título
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Título del artículo"
              {...register('title', { required: true })}
            />
            <label className="form-label mt-3" htmlFor="title">
              URL
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Url del artículo"
              {...register('slug', { required: true })}
            />
            <label className="form-label mt-3">Cuerpo</label>
            {initialData !== null && (
              <ReactEditorJS
                tools={EDITOR_JS_TOOLS}
                defaultValue={initialData}
                onChange={async (e) => setBody(await e.saver.save())}
                minHeight="100px"
                holder="body"
                placeholder="Escribe aquí tu artículo"
              >
                <div id="body"></div>
              </ReactEditorJS>
            )}

            <div
              style={{ zIndex: 10 }}
              className="py-2 px-3 bg-dark w-100 bottom-0 start-0 position-fixed d-flex justify-content-end align-items-center"
            >
              {id && (
                <button
                  type="button"
                  className="btn"
                  onClick={() =>
                    confirmAlert({
                      title: 'Confirmar',
                      message: '¿Estás seguro de eliminar esta publicación?',
                      buttons: [
                        {
                          label: 'Sí',
                          onClick: () => deletePublication(),
                        },
                        {
                          label: 'No',
                        },
                      ],
                    })
                  }
                >
                  <Trash color="white" />
                </button>
              )}
              <button
                type="submit"
                className="btn btn-primary btn-small btn-warning"
              >
                Guardar
              </button>
            </div>
          </Col>
          <Col lg={4} className="px-5">
            <label className="form-label">Estado</label>
            <select
              className="form-select"
              {...register('status', { required: true })}
            >
              <option value="draft">Borrador</option>
              <option value="published">Publicado</option>
            </select>
            <label className="form-label mt-3">Fecha de publicación</label>
            <input
              className="form-control"
              type="datetime-local"
              {...register('publishOn')}
            />
            <label className="form-label mt-3">Sección</label>
            <select
              className="form-select"
              {...register('section', { required: true })}
            >
              <option value="moda">Moda</option>
              <option value="belleza">Belleza</option>
              <option value="lifestyle">Lifestyle</option>
            </select>
            <label className="form-label mt-3">Escrito por</label>
            <select className="form-select" {...register('writtenBy')}>
              <option value="null">DModa</option>
              <option value="2">María José</option>
              <option value="1">Alberto</option>
              <option value="3">Sara</option>
              <option value="4">Irene</option>
            </select>
            <label className="form-label mt-3">Tags</label>
            <input
              className="form-control"
              type="text"
              placeholder="Tags separados por coma"
              {...register('tags')}
            />
            <label className="form-label mt-3">Entradilla</label>
            <textarea
              className="form-control"
              rows="5"
              placeholder="Resumen del artículo"
              {...register('description', { maxLength: 500 })}
            />
            <label className="form-label mt-4">Portada</label>
            {initialFrontImage !== null && (
              <ReactEditorJS
                tools={{
                  image: EDITOR_JS_TOOLS.image,
                  embed: EDITOR_JS_TOOLS.embed,
                }}
                defaultValue={initialFrontImage}
                onChange={async (e) => setFrontImage(await e.saver.save())}
                minHeight="100px"
                holder="frontImage"
                placeholder={
                  pubType === 'video'
                    ? 'Pega una URL de youtube'
                    : 'Pega una URL de una imagen o arrástrala aquí'
                }
              >
                <div id="frontImage"></div>
              </ReactEditorJS>
            )}
          </Col>
        </Row>
      </form>
    </Container>
  );
}
