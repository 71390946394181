import { Container, Nav, Navbar, NavItem } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function Dashboard() {
  return (
    <>
      <Navbar variant="dark" style={{ background: "black", padding: "8px 16px", height: "8vh" }}>
        <Navbar.Brand href="/dashboard/content">DModa</Navbar.Brand>

        <Nav>
          <NavItem>
            <NavLink className="btn btn-link text-white text-decoration-none" to="/dashboard/content">
              Contenido
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="btn btn-link text-white text-decoration-none" to="/dashboard/backlog">
              Backlog
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="btn btn-link text-white text-decoration-none" to="/dashboard/content/new">
              Nuevo
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className="justify-content-end w-100">
          <NavLink className="btn btn-link text-white text-decoration-none" to="/dashboard/pasarela">
            Pasarela
          </NavLink>
        </Nav>
      </Navbar>
      <Container fluid>
        <Outlet />
      </Container>
      <ToastContainer />
    </>
  );
}
